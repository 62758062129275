<template>
  <div>
    <b-card-code title="Details">
      <div class="">
        <h5 class="text-primary">References</h5>
        <!-- v-html="synopsis" -->
        <p class="border-primary p-1 rounded">{{ items.references }}</p>
      </div>
      <div class="mt-1">
        <h5 class="text-primary">Synopsis</h5>
        <!-- v-html="synopsis" -->
        <p class="border-primary p-1 rounded">{{ items.synopsis }}</p>
      </div>
      <div class="mt-1">
        <h5 class="text-primary">Recommendation</h5>
        <!-- v-html="synopsis" -->
        <p class="border-primary p-1 rounded">{{ items.recommendation }}</p>
      </div>
    </b-card-code>
  </div>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BRow,
  BCol,
  BBadge,
  BCard,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSidebar,
  VBToggle,
  BSpinner,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BRow,
    BCol,
    BBadge,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BForm,
    BSpinner,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider,
    ValidationObserver,
    BSidebar,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    portId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      portId: null,
    };
  },

  watch: {
    portId() {
      this.load();
    },
  },

  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "asset/insecure-ports/" + this.portId,
      };
      this.$http(options).then((res) => {
        console.log(res.data, "ddddddddddddddddata");
        this.items = res.data;
      });
    },
  },
};
</script>

<style lang="css" >
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
[dir].dark-layout .list-group-item {
  padding: 0.75rem 1.25rem;
  background-color: #283046 !important;
  border: 1px solid rgb(68 101 129) !important;
}
</style>
