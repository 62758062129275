<template>
  <b-card-code title="">
    <!-- {{ items }} -->
    <router-link class="text-white" :to="'/insecure-port/add'">
      <!-- v-if="this.$store.state.app.user.is_staff" -->
      <b-button
        type="button"
        variant="primary"
        class="btn ml-auto m-1"
        size="sm"
        v-if="
          this.$store.state.app.user.permissions.includes(
            'asset.add_insecureport'
          )
        "
      >
        Add New
      </b-button>
    </router-link>
    <b-table responsive id="insecureTable" :fields="fields" :items="items">
      <!-- <template #cell(created_at)="data">
        <span>{{ new Date(data.item.created_at).toLocaleString() }}</span>
      </template>
      <template #cell(updated_at)="data">
        <span>{{ new Date(data.item.updated_at).toLocaleString() }}</span>
      </template> -->
      <template #cell(actions)="data">
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="gotoDetails(data.item.id)"
            >Details</b-dropdown-item
          >
          <b-dropdown-item @click="editPort(data.item.id)" v-if="isUpdate"
            >Edit</b-dropdown-item
          >

          <b-dropdown-divider />
          <b-dropdown-item
            @click="gotoDelete(data.item.id, data.item.port)"
            v-if="isDelete"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">" {{ portName }} "</span> ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteItem()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="50%"
    >
      <MoreDetails :portId="port_id" />
    </b-sidebar>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BButton,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BModal,
  BSidebar,
} from "bootstrap-vue";
import MoreDetails from "./components/sidebarDetails.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BModal,
    BSidebar,
    MoreDetails,

  },
  //   props: {
  //     org_id: {
  //       type: Number,
  //       required: true,
  //     },
  //   },
  data() {
    return {
      isUpdate: this.$store.state.app.user.permissions.includes(
        "asset.change_insecureport"
      ),
      isDelete: this.$store.state.app.user.permissions.includes(
        "asset.delete_insecureport"
      ),
      fields: [
        { key: "port", label: "Port" },
        { key: "description", label: "Description" },
        // { key: "references", label: "References" },
        // { key: "synopsis", label: "Synopsis" },
        // { key: "recommendation", label: "Recommendation" },
        // { key: "created_at", label: "Created At" },
        // { key: "updated_at", label: "Updated At" },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      openDeleteModal: false,
      port_id: "",
    };
  },
  created: function () {
    // if (this.org_id != null) {
    this.load();
    // }
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/insecure-ports",
      };
      this.$http(options).then((res) => {
        console.log(res.data.results);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
      });
    },
    gotoDetails(id) {
      this.port_id = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-details");
    },
    editPort(id) {
      this.$router.push({ name: "Insecure-port Edit", params: { id: id } });
    },
    gotoDelete(id, port) {
      this.openDeleteModal = true;
      this.port_id = id;
      this.portName = port;
    },
    deleteItem() {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/insecure-ports/" +
          this.port_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
      });
    },
  },
};
</script>